@use 'styles/variables';
@use 'styles/functions';

.achieve-section-container {
  max-width: variables.$maximum-site-content-width;
  padding-left: variables.$default-gutter-width;
  padding-right: variables.$default-gutter-width;
}

@media screen and (min-width: variables.$breakpoint-md) {
  .achieve-section-container {
    padding-left: variables.$medium-and-up-gutter-width;
    padding-right: variables.$medium-and-up-gutter-width;
  }
}
