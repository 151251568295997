@use 'styles/variables';

.main {
  overflow-x: hidden;
  padding-top: 72px; // Offset the height of the fixed mobile header
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

/* Copied from MUI styling to create custom max width container */
.max-width-container {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  max-width: variables.$maximum-site-width;
}

/* Desktop (large or greater) styles */
@media screen and (min-width: variables.$breakpoint-lg) {
  .main {
    overflow-x: initial;
    padding-top: 96px; // Offset the height of the fixed desktop header
  }
}
