@use 'styles/variables';
@use 'styles/functions';

.main-nav {
  background: variables.$color-neutral-background-default;
  border-top: 1px solid variables.$color-neutral-border-default;
  height: 100%;
  overflow: scroll;
  padding-bottom: 160px;
  padding-top: 54px;
  position: fixed;
  right: -100%;
  transition: transform variables.$default-animation-timing;
  width: 100%;
}

.main-nav[data-mobile-nav-open='true'] {
  transform: translateX(-100%);
}

.main-nav > ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-nav-item-title {
  align-items: center;
  color: variables.$color-neutral-black;
  display: flex;
  height: 50px;
  justify-content: center;
  justify-content: space-between;
  transition: variables.$default-animation-timing;
}

.main-nav-item-icon {
  transition: variables.$default-animation-timing;
}

.main-nav-item {
  border-bottom: 1px solid variables.$color-neutral-border-default;

  &[data-selected='true'] {
    .main-nav-item-title {
      color: variables.$color-primary-achieve-blue;
    }
  }

  &[data-active='true'] {
    .main-nav-item-icon {
      transform: rotate(180deg);
    }
  }
}

.main-nav-item > a {
  cursor: pointer;
  display: block;
  text-decoration: none;
  transition: variables.$default-animation-timing;
  padding: 0 (functions.spacing(2));
}

.sub-nav {
  overflow: hidden;
  transition: all variables.$default-animation-timing;
}

.sub-nav-menu ul {
  list-style: none;
  margin: 0;
  padding: 0 (functions.spacing(4));
}

.sub-nav-menu li {
  margin-bottom: functions.spacing(2); // 16px
  margin-top: functions.spacing(2); // 16px
  // manually putting in color from old sunbeam, 6/24 demo, should use color vars
}

.sub-nav-menu-without-subtitle li {
  margin-bottom: functions.spacing(2); // 16px
  margin-top: functions.spacing(2); // 16px
  // manually putting in color from old sunbeam, 6/24 demo, should use color vars

  &:first-child {
    margin-top: functions.spacing(1);
  }
}

.sub-nav-menu-without-subtitle ul {
  list-style: none;
  margin: 0;
  padding: 0 (functions.spacing(4));
}

.sub-nav-link {
  color: variables.$color-neutral-black;
  text-decoration: none;

  &:hover div:first-child,
  &:focus div:first-child {
    color: variables.$color-primary-achieve-blue;
  }

  &[data-selected='true'] div:first-child {
    color: variables.$color-primary-achieve-blue;
  }
}

.sub-nav-link-description {
  color: variables.$color-neutral-grey-0;
}

.main-nav-item > a:last-child {
  border-bottom: none;
}

.menu-title {
  color: variables.$color-neutral-grey-0;
  padding: functions.spacing(1) 0 0 (functions.spacing(2));
}

/* Desktop (large or greater) styles */
@media screen and (min-width: variables.$breakpoint-lg) {
  .sub-nav-menu-without-subtitle {
    width: 368px;

    & + .sub-nav-menu {
      margin-left: functions.spacing(8); // 64px
    }

    li {
      margin-top: 0;
      margin-bottom: 0;

      &:last-child {
        margin-bottom: functions.spacing(3);
      }

      &:first-child {
        margin-top: functions.spacing(3);
      }
    }
  }

  .main-nav {
    border: none;
    height: auto;
    overflow: initial;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    right: 0;
    top: 0;
    transition: none;
    width: auto;
  }

  .main-nav[data-mobile-nav-open='true'] {
    transform: none;
  }

  .main-nav,
  .main-nav > ul,
  .main-nav-item {
    height: 100%;
  }

  .main-nav > ul {
    align-items: stretch;
    flex-direction: row;
  }

  .main-nav-item {
    align-items: stretch;
    border: 0;
    display: flex;
  }

  .main-nav-item > a {
    align-items: center;
    border: 0;
    display: flex;
    padding: 0 (functions.spacing(2));
  }

  .main-nav-item-title {
    height: 100%;
    position: relative;
  }

  .main-nav-item[data-active='true'],
  .main-nav-item[data-selected='true'] {
    .main-nav-item-title {
      color: variables.$color-primary-achieve-blue;
    }
  }

  .main-nav-item[data-selected='true'] {
    .main-nav-item-title::after {
      transition: bottom variables.$default-animation-timing,
        width variables.$default-animation-timing;
      background-color: variables.$color-primary-achieve-blue;
      border-radius: functions.spacing(0.5);
      bottom: functions.spacing(2.25); // 18px - needs to offset the height of the border
      content: '';
      height: functions.spacing(0.5);
      position: absolute;
      width: 100%;
    }
  }

  [data-condensed='true'] .main-nav-item[data-selected='true'] .main-nav-item-title::after {
    bottom: functions.spacing(1.25); // 10px - needs to offset the height of the border
  }

  .sub-nav {
    background-color: variables.$color-neutral-background-default;
    border-radius: 8px;
    border: 1px solid variables.$color-neutral-grey-6;
    box-shadow: 0 1px 14px rgb(0 0 0 / 12%);
    display: none;
    position: absolute;
    transform: translate(-(functions.spacing(3)), (functions.spacing(12))); // -24px, 96px
  }

  [data-active='true'] .sub-nav {
    display: flex;
  }

  .sub-nav ul {
    padding: 0;
  }

  .main-nav[data-condensed='true'] .sub-nav {
    transform: translate(-(functions.spacing(3)), (functions.spacing(8))); // -24px, 64px
  }

  .main-nav[data-condensed='true'] .main-nav-item > a span {
    font-size: 15px;
  }

  .sub-nav-menu {
    width: 368px;

    & + .sub-nav-menu {
      margin-left: functions.spacing(8); // 64px
    }

    li {
      margin-top: 0;
      margin-bottom: 0;

      &:last-child {
        margin-bottom: functions.spacing(3);
      }
    }
  }

  .sub-nav-link {
    display: block;
    padding: functions.spacing(1.5) functions.spacing(5); // 12px 40px

    &:hover,
    &:focus {
      background-color: variables.$color-primary-highlight-blue;
    }
  }

  .menu-title-container {
    margin-bottom: functions.spacing(2);
    padding: functions.spacing(4) functions.spacing(5) 0;
  }

  .menu-title {
    border-bottom: 1px solid variables.$color-neutral-grey-6;
    display: block;
    padding: 0 0 functions.spacing(2);
  }
}
